<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="订单编号：">
        <el-input size="small" v-model="order_no" @input="getKeywords($event, 1)"></el-input>
      </el-form-item>
      <el-form-item label="收货人姓名：">
        <el-input size="small" v-model="receiver_name" @input="getKeywords($event, 2)"></el-input>
      </el-form-item>
      <el-form-item label="收货人手机号：">
        <el-input size="small" v-model="receiver_phone" @input="getKeywords($event, 3)"></el-input>
      </el-form-item>
      <el-form-item label="下单时间：">
        <el-date-picker
          v-model="searchForm.create_time"
          @change="val => (val ? '' : (searchForm.create_time = []))"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="服务名称：">
        <el-input size="small" v-model="goods_name" @input="getKeywords($event, 4)"></el-input>
      </el-form-item>
      <el-form-item label="支付方式：">
        <el-select v-model="searchForm.payment_code" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="微信支付" :value="10"></el-option>
          <el-option label="支付宝支付" :value="20"></el-option>
          <el-option label="华为支付" :value="30"></el-option>
          <el-option label="余额支付" :value="40"></el-option>
          <el-option label="会员卡支付" :value="50"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单来源：">
        <el-select v-model="searchForm.from" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="微信" :value="1"></el-option>
          <el-option label="支付宝" :value="2"></el-option>
          <el-option label="快应用" :value="3"></el-option>
          <el-option label="抖音" :value="4"></el-option>
          <el-option label="QQ" :value="5"></el-option>
          <el-option label="百度" :value="6"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务类别：">
        <el-select v-model="searchForm.goods_type" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="快速需求" :value="1"></el-option>
          <el-option label="找安装" :value="2"></el-option>
          <el-option label="做广告" :value="3"></el-option>
          <el-option label="做设计" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单类型：">
        <el-select v-model="searchForm.order_type" size="small" placeholder="请选择">
          <el-option label="全部" value="whole"></el-option>
          <el-option label="订单报价" :value="0"></el-option>
          <el-option label="指定师傅报价" :value="1"></el-option>
          <el-option label="一口价" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-tabs type="card" v-model="searchForm.state_type" @tab-click="tabClick">
      <el-tab-pane name="all">
        <span slot="label">全部订单（{{ order_num.state_all }}）</span>
      </el-tab-pane>
      <el-tab-pane name="state_new">
        <span slot="label">待确认（{{ order_num.state_new }}）</span>
      </el-tab-pane>
      <el-tab-pane name="state_pay">
        <span slot="label">待进行（{{ order_num.state_pay }}）</span>
      </el-tab-pane>
      <el-tab-pane name="state_send">
        <span slot="label">进行中（{{ order_num.state_send }}）</span>
      </el-tab-pane>
      <el-tab-pane name="state_verification">
        <span slot="label">已完成（{{ order_num.state_verification }}）</span>
      </el-tab-pane>
      <el-tab-pane name="state_cancel">
        <span slot="label">已关闭（{{ order_num.state_cancel }}）</span>
      </el-tab-pane>
    </el-tabs>
    <div class="order-list">
      <table class="order-table">
        <thead>
          <tr>
            <th>服务信息</th>
            <th>服务类别</th>
            <th>订单类型</th>
            <th>支付金额</th>
            <th>支付方式</th>
            <th>提交/付款人</th>
            <th>接单人</th>
            <th>提交时间</th>
            <th>分销佣金</th>
            <th>订单状态</th>
            <th>操作</th>
          </tr>
          <tr style="height: 10px"></tr>
        </thead>
        <tbody v-if="!list.length">
          <tr style="background: #fff">
            <td colspan="11">
              <No />
            </td>
          </tr>
        </tbody>
        <tbody v-for="(item, index) in list" :key="index">
          <tr>
            <td colspan="11">
              <div class="order-head">
                <div class="left">
                  <span class="sn">订单编号：{{ item.pay_sn }}</span>
                  <div v-if="item.store_id">
                    <el-tag type="success">工厂店服务</el-tag>
                    （{{ item.shop_info.store_name }}）
                  </div>
                  <el-tag v-else type="primary">平台服务</el-tag>     
                </div>
                <div class="right">
                  <el-button type="text" @click="$router.push({ path: '/service/orderInfo', query: { id: item.id } })">订单详情</el-button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="service-info">
                <div class="item">
                  <el-image :src="item.goods_type == 1 ? item.extend_order_extend.scene_picture[0] : item.extend_order_goods[0].service_picture"></el-image>
                  <div class="service-title">
                    <p>{{ item.goods_type == 1 ? item.message : item.extend_order_goods[0].service_title }}</p>
                  </div>
                </div>
              </div>
            </td>
            <td>{{ item.goods_type == 1 ? '快速需求' : item.goods_type == 2 ? '找安装' : item.goods_type == 3 ? '做广告' : '做设计' }}</td>
            <td>{{ item.order_type == 0 ? '订单报价' : item.order_type == 1 ? '指定师傅报价' : '一口价' }}</td>
            <td>
              {{ item.amount }}
            </td>
            <td>
              {{ item.payment_code == 10 ? '微信支付' : item.payment_code == 20 ? '支付宝支付' : item.payment_code == 30 ? '华为支付' : item.payment_code == 40 ? '余额支付' : '会员卡支付' }}
            </td>
            <td>
              <p v-show="item.goods_type != 1" style="line-height: 20px">
                {{ item.consignee }}
              </p>
              <p style="line-height: 20px">{{ item.consignee_mobile }}</p>
            </td>
            <td>--</td>
            <td>
              {{ getDateformat(item.create_time) }}
            </td>
            <td>--</td>
            <td>
              <span
                :style="{
                  color: item.state == 0 || item.state == 10 || item.state == 40 ? '#409EFF' : item.state == 20 ? '#f56c6c' : '#e6a23c',
                }"
              >
                <span>
                  {{
                    item.state == 0 ? '已关闭' : item.state == 10 ? '待确认' : item.state == 20 ? '待进行' : item.state == 30 && item.finnshed_time ? '已完工' : item.state == 30 ? '进行中' : '已完成'
                  }}
                </span>
              </span>
            </td>
            <td>
              <!-- <p style="margin-bottom: 5px">
                <el-button v-if="item.state == 10" type="primary" size="mini" @click.stop="confirmReceipt(index)">派单</el-button>
              </p>
              <el-link v-if="item.state == 10" @click.stop="cancelOrder(item)" type="primary" :underline="false">取消订单</el-link> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>
<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      order_no: '',
      receiver_phone: '',
      receiver_name: '',
      goods_name: '',
      order_num: {},
      searchForm: {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        from: '',
        payment_code: '',
        order_type: 'whole',
        goods_type: '',
        state_type: 'all',
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    tabClick() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList(1);
    },
    search() {
      this.searchForm.page = 1;

      this.getList();
    },
    cancelSearch() {
      this.order_no = '';
      this.receiver_phone = '';
      this.receiver_name = '';
      this.goods_name = '';
      this.searchForm = {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        from: '',
        payment_code: '',
        order_type: 'whole',
        goods_type: '',
        state_type: 'all',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    getKeywords(val, type) {
      let searchForm = this.searchForm;
      switch (type) {
        case 1:
          this.receiver_phone = '';
          this.receiver_name = '';
          this.goods_name = '';
          searchForm.keywords_type = 'order_no';
          break;
        case 2:
          this.receiver_phone = '';
          this.order_no = '';
          this.goods_name = '';
          searchForm.keywords_type = 'receiver_name';
          break;
        case 3:
          this.order_no = '';
          this.receiver_name = '';
          this.goods_name = '';
          searchForm.keywords_type = 'receiver_phone';
          break;
        case 4:
          this.order_no = '';
          this.receiver_name = '';
          this.receiver_phone = '';
          searchForm.keywords_type = 'goods_name';
          break;
      }
      searchForm.keywords = val;
    },
    getList(is_tab) {
      let searchForm = Object.assign(this.searchForm);
      let data = {
        page: searchForm.page,
        rows: searchForm.rows,
        state_type: searchForm.state_type,
      };
      if (searchForm.keywords) {
        data.keywords = searchForm.keywords;
        data.keywords_type = searchForm.keywords_type;
      }
      if (searchForm.from) {
        data.from = searchForm.from;
      }
      if (searchForm.payment_code) {
        data.payment_code = searchForm.payment_code;
      }
      if (searchForm.goods_type) {
        data.goods_type = searchForm.goods_type;
      }
      if (searchForm.order_type != 'whole') {
        data.order_type = searchForm.order_type;
      }
      if (searchForm.create_time.length) {
        for (let i in searchForm.create_time) {
          searchForm.create_time[i] = searchForm.create_time[i] / 1000;
        }
        data.create_time = searchForm.create_time;
      }
      this.$axios.post(this.$api.samecity.service.orderList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
      if (!is_tab) {
        this.$axios.post(this.$api.samecity.service.orderNum, data).then(res => {
          if (res.code == 0) {
            this.order_num = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background: #fff;
  .order-table {
    width: 100%;
    font-size: 14px;
    tbody {
      border: 1px solid #ebeef5;
    }
    thead tr th {
      padding: 15px;
      text-align: center;
    }
    thead tr {
      background: #f8f9fa;
      th:first-child {
        width: 280px;
      }
      th:last-child {
        width: 150px;
      }
    }
    tbody tr:first-child {
      border-bottom: 1px solid #ebeef5;

      background: #f8f9fa;
      td {
        padding: 0 20px;
      }
    }
    tbody tr:last-child {
      td {
        text-align: center;
      }
      td:first-child {
        width: 280px;
      }
      td:last-child {
        width: 150px;
      }
    }
    .service-info {
      width: 300px;
      flex: none;
      padding-left: 20px;

      .item {
        height: 70px;
        display: flex;
        align-items: center;
        .el-image {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          border-radius: 5px;
        }
        .service-title {
          text-align: left;
          flex: 1;
          p:nth-child(1) {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    .order-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        .sn {
          margin-right: 70px;
        }
        div {
          display: flex;
          align-items: center;
        }
        .el-button {
          cursor: auto;
        }
      }
    }
  }
}
</style>